import { HomeTitle } from '@components/com/home-title';
import React from 'react';
import styled from 'styled-components';
import rolePng from '@assets/images/role.png';
import chatPng from '@assets/images/chat.png';
import cardPng from '@assets/images/card.png';

const Contents = [
  {
    icon: rolePng,
    title: 'Character Profiles',
    text: 'Dive into the rich and captivating backstories of each goddess, from the nurturing and wise Idun to the fierce and independent Diana, and the enigmatic and creative Nuwa, each embodying unique traits, powers, and personalities that will captivate and inspire users.',
  },
  {
    icon: chatPng,
    title: 'AI Capabilities',
    text: 'Experience the extraordinary AI capabilities of each goddess as they assist users with tasks, provide guidance and advice, engage in meaningful conversations, and offer a personalized and interactive experience that transcends traditional chatbot interactions.',
  },
  {
    icon: cardPng,
    title: 'Card Checklists',
    text: 'Explore the diverse and enchanting range of cards available, from common R cards to rare UR-S, SP-S, and post-Singularity MM cards, each meticulously designed to showcase the beauty, power, and essence of the goddesses, unlocking new dimensions of interaction, discovery, and collectibility.',
  },
];

export const HomeGame: React.FC<{ className?: string }> = (props) => {
  return (
    <CptStyle className={props.className}>
      <HomeTitle className="title">Game</HomeTitle>
      <div className="content">
        {Contents.map((item) => {
          return (
            <div key={item.text}>
              <img src={item.icon} alt="" />
              <div className="title">{item.title}</div>
              <div className="text">{item.text}</div>
            </div>
          );
        })}
      </div>
    </CptStyle>
  );
};
const CptStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  > .content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding-top: 130px;
    flex-wrap: wrap;
    gap: 90px;
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 360px;
      text-align: left;
      > img {
        width: 318px;
      }
      > .title {
        font-size: 30px;
        font-weight: bold;
        margin-top: 40px;
        color: #ffecb2;
        width: 100%;
      }
      > .text {
        font-size: 18px;
        margin-top: 30px;
        line-height: 1.4;
        color: #fff1e1;
      }
    }
  }
`;
