import React from 'react';
import styled from 'styled-components';
import RoleBgJpg from '@assets/images/top.jpg';
import TitlePng from '@assets/images/title.png';
import BtnPng from '@assets/images/btn.png';
import tgPng from '@assets/images/tg.png';
import { AppConstants } from 'constant/constant';

export const HomeMain: React.FC<{ className?: string }> = (props) => {
  return (
    <CptStyle className={props.className}>
      <img src={RoleBgJpg} alt="" className="RoleBgJpg" />
      <div className="content">
        <img src={TitlePng} className="TitlePng" alt="" />
        <div className="welcome">Welcome to GoddessAI: Where Mythology Meets the Future</div>
        <div className="btn ani-cute">
          <img src={BtnPng} alt="" />
          <a href={AppConstants.telegramApp} target="_blank" rel="noreferrer">
            <img src={tgPng} alt="" />
            Lanunch MiniAPP
          </a>
        </div>
      </div>
    </CptStyle>
  );
};
const CptStyle = styled.div`
  width: 100vw;
  position: relative;
  > img {
    width: 100vw;
    display: block;
  }
  > .content {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 32vw;
    z-index: 2;
    width: 66.2vw;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    > .TitlePng {
      width: 66.14vw;
    }
    > .welcome {
      font-size: 26px;
      color: #fff1e1;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(50, 35, 27, 0.2);
      border-radius: 100px;
      text-align: center;
      margin-top: -2vw;
      line-height: 1;
    }
    > .btn {
      margin-top: 2vw;
      width: 426px;
      height: 145px;
      position: relative;
      cursor: pointer;
      user-select: none;
      > img {
        width: 100%;
      }
      > a {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 2.3vw;
        font-size: 26px;
        font-weight: bold;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
      }
    }
  }
  @media screen and (max-width: 750px) {
    > .content {
      top: 133vw;
      > .TitlePng {
        width: 100vw;
      }
      > .welcome {
        font-size: 18px;
      }
      > .btn {
        height: 121px;
        transform: scale(0.8);
      }
    }
    > .RoleBgJpg {
      width: 100vw;
      height: 288vw;
      object-fit: cover;
    }
  }
`;
