import React from 'react';
import styled from 'styled-components';
import LeftPng from '@assets/images/title-left.png';
import BottomPng from '@assets/images/title-bottom.png';

export const HomeTitle: React.FC<{ className?: string; children?: React.ReactNode }> = (props) => {
  return (
    <CptStyle className={props.className}>
      <img src={LeftPng} className="LeftPng" alt="" />
      <img src={LeftPng} className="LeftPng RightPng" alt="" />
      <div className="BottomPng" />
      <div className="BottomPng2" />
      {props.children}
    </CptStyle>
  );
};
const CptStyle = styled.div`
  position: relative;
  padding: 6px 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #94867b;
  font-size: 36px;
  height: 60px;
  font-weight: bold;
  > .LeftPng {
    position: absolute;
    left: 0;
    top: 39px;
    &.RightPng {
      left: auto;
      right: 0;
      transform: scale(-1, 1);
    }
  }
  > .BottomPng {
    position: absolute;
    bottom: 7px;
    left: 46px;
    right: 46px;
    background-image: url(${BottomPng});
    background-repeat: repeat-x;
    height: 10px;
  }
  > .BottomPng2 {
    position: absolute;
    bottom: 1px;
    left: 64px;
    right: 64px;
    background-image: url(${BottomPng});
    background-repeat: repeat-x;
    height: 10px;
  }
`;
