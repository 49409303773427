import { HomeTitle } from '@components/com/home-title';
import React from 'react';
import styled from 'styled-components';
import teamBgPng from '@assets/images/team-bg.png';
import member1Png from '@assets/images/avatar/a1.png';
import member2Png from '@assets/images/avatar/a2.png';
import member3Png from '@assets/images/avatar/a3.png';

const TeamMembers = [
  {
    avatar: member1Png,
    name: 'EdwardZ',
    description: `EdwardZ, our Web3.0 wizard, merges our memes with blockchain sorcery, crafting tokens that captivate and redefine. With imagination and technical mastery, EdwardZ's creations unlock a realm where levity harmonizes with cutting-edge technology.`,
  },
  {
    avatar: member2Png,
    name: 'RoY',
    description: `Roy, our AI alchemist, infuses our chatbots with charm and personality, creating conversations that feel natural and engaging. With a touch of wit and a flair for dialogue, RoY ensures that every interaction is a delightful and memorable experience.`,
  },
  {
    avatar: member3Png,
    name: 'AleX',
    description: `AleX, our card conjurer, weaves spells with each trading card, turning them into portals of wonder and beauty. With a dash of magic and a sprinkle of creativity, AleX's designs captivate hearts and minds, transcending the ordinary to spark imagination and awe.`,
  },
];

export const HomeTeam: React.FC<{ className?: string }> = (props) => {
  return (
    <CptStyle className={props.className}>
      <HomeTitle className="title">Team</HomeTitle>
      <div className="content">
        {TeamMembers.map((member, index) => (
          <div key={index} className="member">
            <div className="avatar">
              <img src={member.avatar} alt="" />
            </div>
            <div className="info">
              <div className="name">{member.name}</div>
              <div className="description">{member.description}</div>
            </div>
          </div>
        ))}
      </div>
    </CptStyle>
  );
};

const CptStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 200px;
  padding-bottom: 200px;

  > .content {
    display: flex;
    margin-top: 100px;
    padding: 0 40px;
    max-width: 1440px;
    width: 100%;
    justify-content: space-evenly;
    flex-wrap: wrap;

    > .member {
      width: 408px;
      height: 665px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      background-image: url(${teamBgPng});
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;

      > .avatar {
        width: 208px;
        margin: 65px 0 0 91px;
        > img {
          width: 208px;
          height: 216px;
        }
      }

      > .info {
        padding: 0 54px 0 32px;
        transform: rotate(1.4deg);
        > .name {
          font-size: 30px;
          font-weight: bold;
          color: #ffecb2;
          margin-bottom: 10px;
          text-align: center;
        }
        > .description {
          font-size: 18px;
          line-height: 1.2;
          color: #fff1e1;
          opacity: 0.8;
          text-align: center;
        }
      }
    }
  }
`;
