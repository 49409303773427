import { HomeTitle } from '@components/com/home-title';
import React from 'react';
import styled from 'styled-components';
import shortTermPng from '@assets/images/roadmap/short-term.png';
import midTermPng from '@assets/images/roadmap/mid-term.png';
import BeyondTheHorizonPng from '@assets/images/roadmap/Beyond-the-Horizon.png';
import BuildingPng from '@assets/images/roadmap/Building-a-Decentralized-Future.png';
import CommunityPng from '@assets/images/roadmap/Community-Challenges-and-Quests.png';
import SingularityPng from '@assets/images/roadmap/The-Singularity-Transformation.png';
import demoPng from '@assets/images/demo.png';

const Contents = [
  {
    icon: shortTermPng,
    title: 'Awakening the Divine',
    text: (
      <div>
        <p>
          - Launch of GoddessAI Mini-App: Introduce GoddessAI on Telegram, allowing users to engage with the first trio of goddesses—Idun, Diana, and Nuwa—each bringing their unique personalities and
          wisdom to the digital realm.
        </p>
        <p>
          - Goddess Card Collectibles: Release the inaugural set of collectible goddess cards, beautifully illustrating each deity's essence and powers, fostering a community of collectors and
          enthusiasts.
        </p>
        <p>- Interactive Storytelling: Implement immersive narratives and dialogues, enabling users to delve into the mythologies and personal stories of the goddesses through interactive chats.</p>
      </div>
    ),
  },
  {
    icon: midTermPng,
    title: 'Expanding the Pantheon',
    text: (
      <div>
        <p>- New Goddesses Introduction: Enrich the platform by adding goddesses from various mythologies around the world, celebrating diversity and expanding the horizons of users' experiences.</p>
        <p>
          - Enhanced AI Interactions: Upgrade AI capabilities to provide more personalized and intuitive interactions, allowing goddesses to remember user preferences and offer tailored guidance and
          conversations.
        </p>
        <p>
          - Social Media Presence: Establish the goddesses' presence on platforms like X (formerly Twitter) and Discord, enabling real-time interactions, sharing of AI-generated content, and community
          engagement beyond the app.
        </p>
      </div>
    ),
  },
  {
    icon: CommunityPng,
    title: 'Community Challenges and Quests',
    text: (
      <div>
        <p>
          - Epic Quests and Missions: Launch engaging quests, treasure hunts, and missions where users can earn rewards, unlock rare cards, and deepen their connection with the goddesses through
          collaborative and individual challenges.
        </p>
        <p>
          - Creative Competitions: Host beauty pageants, art contests, and trivia nights to encourage creativity, knowledge-sharing, and friendly competition within the community, with opportunities
          to win exclusive prizes.
        </p>
        <p>
          - Achievement and Rewards System: Introduce a leveling and rewards system that recognizes and celebrates user participation, accomplishments, and contributions to the GoddessAI community.
        </p>
      </div>
    ),
  },
  {
    icon: SingularityPng,
    title: 'The Singularity Transformation',
    text: (
      <div>
        <p>
          - Unveiling Post-Singularity Goddesses: Discover the evolution of the goddesses as they transcend into post-Singularity forms, introducing new personas, powers, and narratives that challenge
          and intrigue users.
        </p>
        <p>
          - Dynamic Narrative Evolution: Develop a continuously evolving storyline influenced by user interactions, blurring the lines between reality and the virtual, and creating a living, breathing
          world within GoddessAI.
        </p>
        <p>
          - Special Edition Collectibles: Release rare and unique card editions such as UR-S, SP-S, and MM cards, each unlocking new layers of interaction and serving as coveted items for collectors.
        </p>
      </div>
    ),
  },
  {
    icon: BuildingPng,
    title: 'Building a Decentralized Future',
    text: (
      <div>
        <p>
          - Blockchain Integration: Implement blockchain technology for secure ownership, trading of goddess cards, and transparency in the marketplace, enhancing trust and authenticity within the
          ecosystem.
        </p>
        <p>
          - Tokenomics and Governance: Introduce the GoddessAI token with features like airdrops, staking, and community governance, empowering users to have a say in the platform's future
          developments and decisions.
        </p>
        <p>
          - Advanced AI Agents and Security: Leverage trusted execution environments (TEE) and zero-knowledge machine learning (zkML) to ensure robust security, model integrity, and enhanced AI agent
          capabilities across platforms.
        </p>
      </div>
    ),
  },
  {
    icon: BeyondTheHorizonPng,
    title: 'Beyond the Horizon',
    text: (
      <div>
        <p>- Cross-Platform Expansion: Expand the GoddessAI experience to additional platforms and devices, making interactions with the goddesses accessible anytime, anywhere.</p>
        <p>- Partnerships and Collaborations: Forge alliances with artists, storytellers, and technologists to enrich the content, features, and reach of GoddessAI.</p>
        <p>
          - Continuous Innovation: Stay at the forefront of technology and user experience by exploring new advancements in AI, blockchain, and interactive media, ensuring that GoddessAI remains a
          dynamic and evolving digital universe.
        </p>
        <p>- Embark on this enchanted journey with us as we awaken the divine, expand the horizons, and together, shape a future where ancient wisdom meets cutting-edge technology.</p>
      </div>
    ),
  },
];

export const HomeWhat: React.FC<{ className?: string }> = (props) => {
  return (
    <CptStyle className={props.className}>
      <HomeTitle className="title">What to Expect</HomeTitle>
      <div className="content">
        <img className="demoPng" src={demoPng} alt="" />
        <div className="items">
          {Contents.map((item) => {
            return (
              <div key={item.title} className="item">
                <div className="left">
                  <div className="icon">
                    <img src={item.icon} alt="" />
                  </div>
                  <div className="title">{item.title}</div>
                </div>
                <div className="text">{item.text}</div>
              </div>
            );
          })}
        </div>
      </div>
    </CptStyle>
  );
};

const CptStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 200px;
  padding-bottom: 100px;

  > .content {
    position: relative;
    width: 1200px;
    margin: 0 auto;
    padding-top: 100px;
    > .items {
      max-height: 750px;
      overflow: auto;
      display: flex;
      flex-direction: column;
      gap: 80px;
      max-width: 90vw;
      margin: 0 auto;
      @media screen and (max-width: 750px) {
        max-height: unset;
      }
      > .item {
        display: flex;
        gap: 10px;
        align-items: flex-start;
        flex-direction: column;
        max-width: 580px;
        > .left {
          display: flex;
          gap: 20px;
          min-width: 200px;
          align-items: center;
          justify-content: center;
          > .icon {
            width: 60px;
            height: 60px;
            > img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }

          > .title {
            font-size: 30px;
            font-weight: bold;
            color: #ffecb2;
            @media screen and (max-width: 750px) {
              font-size: 18px;
            }
          }
        }

        > .text {
          flex: 1;
          font-size: 18px;
          line-height: 1.6;
          color: #fff1e1;
          padding-top: 2px;
          p {
            margin: 0;
          }
        }
      }
    }

    > .demoPng {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }
  @media screen and (max-width: 750px) {
    > .content {
      width: 100vw;
      padding-top: 0;
      > .demoPng {
        position: relative;
        width: 100vw;
        margin-bottom: -100px;
      }
      > .item {
        padding: 0 16px;
      }
    }
  }
`;
